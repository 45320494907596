exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-accessibility-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/sb-portfolio/sb-portfolio/src/projects/accessibility.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-accessibility-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-design-systems-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/sb-portfolio/sb-portfolio/src/projects/design-systems.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-design-systems-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-games-apps-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/sb-portfolio/sb-portfolio/src/projects/games-apps.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-games-apps-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-web-applications-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/sb-portfolio/sb-portfolio/src/projects/web-applications.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-web-applications-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-wordpress-builds-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/sb-portfolio/sb-portfolio/src/projects/wordpress-builds.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-projects-wordpress-builds-mdx" */),
  "component---src-projects-accessibility-mdx": () => import("./../../../src/projects/accessibility.mdx" /* webpackChunkName: "component---src-projects-accessibility-mdx" */),
  "component---src-projects-design-systems-mdx": () => import("./../../../src/projects/design-systems.mdx" /* webpackChunkName: "component---src-projects-design-systems-mdx" */),
  "component---src-projects-games-apps-mdx": () => import("./../../../src/projects/games-apps.mdx" /* webpackChunkName: "component---src-projects-games-apps-mdx" */),
  "component---src-projects-web-applications-mdx": () => import("./../../../src/projects/web-applications.mdx" /* webpackChunkName: "component---src-projects-web-applications-mdx" */),
  "component---src-projects-wordpress-builds-mdx": () => import("./../../../src/projects/wordpress-builds.mdx" /* webpackChunkName: "component---src-projects-wordpress-builds-mdx" */)
}

